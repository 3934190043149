// @ts-nocheck
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import { LoaderV } from '../widgets/LoaderV';

const DashboardScreen = lazy(() => import('../screens/Dashboard'));
const LoginScreen = lazy(() => import('../screens/Login'));
const SignupScreen = lazy(() => import('../screens/Signup'));
const RegistrationScreen = lazy(() => import('../screens/Registration'));
const NotFoundScreen = lazy(() => import('../screens/PageNotFound'));
const TermsandConditionScreen = lazy(() => import('../screens/TermsAndCondition'));
const PrivacyScreen = lazy(() => import('../screens/PrivacyPolicy'));
const ForgotPasswordScreen = lazy(() => import('../screens/ForgotPassword'));
const ResetPasswordScreen = lazy(() => import('../screens/ResetPassword'));
const VerifierScreen = lazy(() => import('../screens/VerifierView'));
const FaqScreen = lazy(() => import('../screens/Faqs'));
const LandingScreen = lazy(() => import('../screens/LandingPage'));

import { useAuth } from '../services/auth-service/auth.provider';

const { isAuthenticated } = useAuth();

const RouterConfig = props => (
  <Switch>
    <PrivateRoute path="/dashboard" component={DashboardScreen} />
    <Route path="/login" component={LoginScreen} />
    <Route path="/signup" component={SignupScreen} />
    <Route path="/register" component={RegistrationScreen} />
    {/* <Route path="/dashboard" component={DashboardScreen} /> */}
    {/* <PrivateRoute path="/verify" component={VerifyUserScreen} /> */}
    {/* <Route path="/credential" component={ViewCertificateScreen} /> */}
    <Route path="/hello" component={NotFoundScreen} />
    <Route path="/termsandcondition" component={TermsandConditionScreen} />
    <Route path="/privacy" component={PrivacyScreen} />
    <Route path="/forgotpassword" component={ForgotPasswordScreen} />
    <Route path="/resetpassword" component={ResetPasswordScreen} />
    <Route path="/credential" component={VerifierScreen} />
    <Route path="/faq" component={FaqScreen} />
    <Route path="/" component={LandingScreen} />
  </Switch>
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
    }
  />
);

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

const Stack = createStackNavigator();

const AppNavigatorInternal = (): JSX.Element => {
  return (
    <NavigationContainer>
      <Stack.Navigator headerMode={'none'}>
        <Stack.Screen name="Dashboard" component={DashboardScreen} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export const AppNavigator = (): JSX.Element => {
  return (
    <Router>
      <Suspense fallback={<LoaderV loadingText={'loading'} />}>
        <RouterConfig />
      </Suspense>
    </Router>
  );
};
